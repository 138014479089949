import * as VueRouter from "vue-router";
import store from './store.js';

const routes = [
    {
        path: '/',
        name:'root',
        redirect: '/home',
        meta: { requiresAuth: true, title: 'Toolbox' },
        component: () => import('../base/NavFrame.vue'),
        children: []
    },
    {
        path: '/login',
        name:'Login',
        component: () => import('../base/LoginPage.vue')
    },
]

const router = VueRouter.createRouter({
    history: VueRouter.createWebHistory(),
    routes, // short for `routes: routes`
})

router.beforeEach( (to, from, next) => {
    if(!store) {
        next()
        return
    }
    if (to.matched.some(record => record.meta?.requiresAuth)) {
        if (store.state.main.token === undefined || store.state.main.token.length === 0) {
            next({
                path: '/login',
                query: {redirect: to.fullPath}
            })
        } else {
            next()
        }
    } else {
        next() // make sure to always call next()!
    }
})

export default router;