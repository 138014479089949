// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import {createVuetify} from 'vuetify';
import {VStepper, VStepperActions} from 'vuetify/labs/VStepper'
import {
    VDataTable,
    VDataTableServer,
    VDataTableVirtual,
} from "vuetify/labs/VDataTable";
import {themes} from "@/apps/themes";

export default createVuetify({
        components: {
            VStepper,
            VStepperActions,
            VDataTable,
            VDataTableServer,
            VDataTableVirtual,
        },
    theme: {
        defaultTheme: 'def',
        themes: themes,
    }
})
