import {createApp} from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './plugins/router.js'
import {getStore} from './plugins/store.js';

const prefix = location.hostname.split('.')[0]
Promise.all([import('@/apps/'+ prefix + '/routes.js'),import('@/apps/'+prefix+'/store.js')]).then((results) => {
    createApp(App)
        .use(vuetify)
        .use(router)
        .use(getStore(results[1].default))
        .mount('#app')
})