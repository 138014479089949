
export const skin = {
    loginLogo: '/login-logo.png',
    loginBackground: '/login-bg.png',
    headerLogo: '/header-logo.png',
    headerLink: 'https://armordata.com',
    title: 'App2',
    theme: {
        dark: false,
        colors: {
            background: '#FFFFFF',
            surface: '#FFFFFF',
            primary: '#FF0000',
            'primary-darken-1': '#3700B3',
            secondary: '#ff725e',
            'secondary-darken-1': '#018786',
            error: '#B00020',
            info: '#2196F3',
            success: '#4CAF50',
            warning: '#FB8C00',
        },
    }
}
