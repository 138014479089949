import axios from 'axios';
import {store} from '../plugins/store';
import router from '../plugins/router';
import Qs from 'qs';

export class ApiError extends Error {
    status = -1;
    statusText = 'unknown';
    data = undefined;
    err = undefined

    constructor(err) {
        super(err.message)
        if (err.response) {
            this.status = err.response.status;
            this.statusText = err.response.statusText;
            if (err.response.data) {
                this.data = err.response.data;
                this.message = err.response.data;
                if (err.response.data.message) {
                    this.message = err.response.data.message;
                }
            }
        }
        if (process.env.VUE_APP_API_LOG === 'true') {
            console.log(this)
        }
    }
}

export function ApiCall(method, url, query, data, timeout) {
    return new Promise((resolve, reject) => {

        if (!timeout || timeout < 0) {
            timeout = 10000
        }

        const config = {
            url: process.env.VUE_APP_API_URL + url,
            method: method,
            timeout: timeout,
            paramsSerializer: function (params) {
                return Qs.stringify(params, {arrayFormat: 'repeat', indices: false})
            },
        };
        if (store.state.main.token) {
            config.headers = {Authorization: 'Bearer ' + store.state.main.token}
        }

        if (query) {
            config.params = query;
        }

        if (data) {
            config.data = data;
        }

        axios.request(config).then((response) => {
            if (process.env.VUE_APP_API_LOG === 'true') {
                console.log(response)
            }
            if (response.data) {
                response.data._headers = response.headers
                resolve(response.data);
            } else {
                resolve(undefined);
            }
        }).catch((err) => {
            console.log(err)
            if (err.response && err.response.status === 401) {
                router.push('/login').catch((failure) => {
                    //do nothing
                    const failure2 = failure
                    failure = failure2
                })
                return;
            }
            reject(new ApiError(err))
        })


    })
}