// this is the default skin that will be applied if no skin is found for the host
export const skin = {
    loginLogo: '/login-logo.png',
    loginBackground: '/login-bg.png',
    headerLogo: '/header-logo.png',
    title: 'Unknown',
    theme: {
        dark: false,
        colors: {
            background: '#FFFFFF',
            surface: '#FFFFFF',
            primary: '#27aae1',
            'primary-darken-1': '#3700B3',
            secondary: '#ff725e',
            'secondary-darken-1': '#018786',
            error: '#B00020',
            info: '#2196F3',
            success: '#4CAF50',
            warning: '#FB8C00',
        },
    }
}
