<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script setup>

//const theme = {}
import {useTheme} from "vuetify";

const theme = useTheme()
const prefix = location.hostname.split('.')[0]
if(theme.themes.value[prefix]) {
  theme.global.name.value = prefix
}

</script>
