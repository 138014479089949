import {createStore} from 'vuex';
import VuexPersistence from 'vuex-persist';
import axios from "axios";
import qs from "qs";
import {ApiError} from "@/services/api";
import router from "@/plugins/router";

// Create a new store instance.

const moduleMain = {
    state() {
        return {
            token: '',
            user: {},
            account: {},
            contract: {},
        }
    },
    mutations: {
        setToken(state, data) {
            state.token = data.token;
            state.user = data.user;
            state.account = data.account;
            if (data.contract) {
                state.contract = data.contract;
            } else {
                state.contract = {}
            }
        },
        clearToken(state) {
            state.token = '';
            state.user = {};
            state.account = {};
            state.contract = {};
        },
    },
    actions: {
        login({commit}, credentials) {
            return new Promise((resolve, reject) => {
                const data = qs.stringify({emailAddress: credentials.emailAddress, password: credentials.password})
                const config = {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }
                axios.post(process.env.VUE_APP_API_URL + 'login', data, config).then((response) => {
                    commit('setToken', response.data);
                    resolve();
                }).catch((err) => {
                    reject(new ApiError(err))
                })
            })
        },
        logout({commit}) {
            return new Promise((resolve) => {
                commit('clearToken');
                router.push('/login');
                resolve()
            })
        }
    }
}

export let store = undefined
export function getStore(appStore) {
    store = createStore({
        modules: {
            main: moduleMain,
            app: appStore
        },
        plugins: [
            new VuexPersistence({storage: window.localStorage}).plugin
        ]
    })
    return store
}

export default store;